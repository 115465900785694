import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import Faq from "../components/Faq";
import VertragsserviceHeader from "../components/VertragsserviceHeader";
import Layout from "../components/Layout";
import VertragsserviceProcessExplanationShort from "../components/VertragsserviceProcessExplanationShort";
import ProductInCards from "../components/ProductInCards";
import MediaLogos from "../components/MediaLogos";
import BreadcrumbList from "../components/seo/BreadcrumbList";
import MetaComponents from "../components/seo/MetaComponents";
import { urls, extendUrlWithSourceVersion } from "../constants/urls";
import HeaderImage from "../assets/images/vertragsservice-header-image.png";

const Vertragsservice = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <VertragsserviceHeader image={HeaderImage} />
            <VertragsserviceProcessExplanationShort hasMarginTop />
            <MediaLogos hasGreyBg hasMarginTop />
            <ProductInCards hasMarginTop theme="dark" CTAUrl={extendUrlWithSourceVersion(urls.survey)} />
            <Faq hasMarginTop ctaText="Mehr Antworten" ctaUrl="/faq" />
            <BreadcrumbList page={"vertragsservice"}></BreadcrumbList>
        </Layout>
    );
};

export default Vertragsservice;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "vertragsserviceHeader", "page.vertragsservice", "indexFaq"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
