import React from "react";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import styled from "styled-components";

import { ContentWrapper, H2, GreyBg } from "../assets/styles/base";
import { colors } from "../constants/colors";
import { breakpoints } from "../constants/breakpoints";
import desktopImage from "../assets/svg/processexplanation-miracl-desktop.svg";
import BottomIcons from "../assets/svg/time-money-icon-miracl.svg";
import mobileImage from "../assets/svg/processexplanation-miracl-mobile.svg";

const VertragsserviceProcessExplanationShort = ({ hasGreyBg, hasMarginTop }) => {
    const { t } = useTranslation();
    const processExplanation = t("processExplanation", { returnObjects: true });
    const Content = (
        <StyledContentWrapper hasMarginTop={hasMarginTop}>
            <H2 center>{processExplanation.title}</H2>

            <Wrapper>
                <DesktopVersion className="processShort" src={desktopImage} alt="" />
                <MobileVersion className="processShort" src={mobileImage} alt="" />
                <ul>
                    {processExplanation.processBullets.map((item, i) => (
                        <li key={`processB-${i}`}>
                            <Trans>{item}</Trans>
                        </li>
                    ))}
                </ul>
            </Wrapper>
            <BottomTextMobile className="bottomText">
                <Trans>{processExplanation.mobileTextProcess[0]}</Trans>&nbsp;
                <strong className="color-primary">
                    <Trans>{processExplanation.mobileTextProcess[1]}</Trans>
                </strong>
                &nbsp;
                <Trans>{processExplanation.mobileTextProcess[2]}</Trans>
                <img src={BottomIcons} alt="" height={55} />
            </BottomTextMobile>
            <BottomTextDesktop className="bottomText">
                <span>
                    <Trans>{processExplanation.desktopTextProcess[0]}</Trans>&nbsp;
                    <strong className="color-primary">
                        <Trans>{processExplanation.desktopTextProcess[1]}</Trans>
                    </strong>
                    &nbsp;
                    <Trans>{processExplanation.desktopTextProcess[2]}</Trans>
                </span>
                <img src={BottomIcons} alt="" height={55} />
                <span>
                    <Trans>{processExplanation.desktopTextProcess[3]}</Trans>
                </span>
            </BottomTextDesktop>
        </StyledContentWrapper>
    );
    if (hasGreyBg) return <GreyBg>{Content}</GreyBg>;
    return Content;
};

const StyledContentWrapper = styled(ContentWrapper)`
    color: ${colors.primary};
    margin-top: ${({ hasMarginTop }) => (hasMarginTop ? "50px" : "0")};

    @media (min-width: ${breakpoints.tabletBigMin}) {
        margin-top: ${({ hasMarginTop }) => (hasMarginTop ? "100px" : "0")};
    }
`;

const DesktopVersion = styled.img`
    display: none;

    @media (min-width: ${breakpoints.tabletMin}) {
        display: inline-block;
    }
`;

const MobileVersion = styled.img`
    display: block;

    @media (min-width: ${breakpoints.tabletMin}) {
        display: none;
    }
`;

const BottomTextMobile = styled.p`
    color: #282828;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 30px 0 0;
    padding: 0 5px;

    img {
        margin-top: 15px;
        width: 100%;
    }

    @media (min-width: ${breakpoints.tabletMin}) {
        display: none;
    }
`;
const BottomTextDesktop = styled.p`
    color: #282828;
    align-items: center;
    display: none;
    flex-wrap: nowrap;
    justify-content: center;
    margin: 30px 0 0;
    padding: 0 5px;

    span {
        display: inline-block;
        white-space: nowrap;
        width: 150px;

        &:first-child {
            text-align: right;
        }
    }
    img {
        margin: -2px 15px 0 15px;
    }

    @media (min-width: ${breakpoints.tabletMin}) {
        display: flex;
    }
`;

const Wrapper = styled.div`
    color: #282828;
    font-size: 14px;
    line-height: 18px;
    position: relative;

    .processShort {
        margin: 0;
        right: 60%;
        position: absolute;
        width: 100px;
    }
    ul {
        list-style: none;
        margin: 0 0 0 40%;
        padding: 20px 0 40px;
    }

    li {
        height: 110px;

        &:last-child {
            height: auto;
        }
    }

    @media (min-width: ${breakpoints.tabletMin}) {
        display: block;
        font-size: 16px;
        line-height: 24px;
        margin-top: 40px;
        text-align: center;

        .processShort {
            margin: 0 10%;
            position: static;
            width: 80%;
        }
        ul {
            display: flex;
            justify-content: space-between;
            margin: 0 auto;
            padding: 0;
            width: 86%;
        }
        li {
            height: auto;
            width: 25%;
        }
        ~ .bottomText {
            margin: 74px 0 0;
        }
    }

    @media (min-width: 1260px) {
        li {
            span {
                display: none;
            }
        }
        ~ .bottomText {
            img {
                height: 55px;
                margin-top: -4px;
            }
        }
    }

    @media (min-width: ${breakpoints.tabletMin}) and (max-width: 850px) {
        font-size: 13px;
        line-height: 20px;
        margin-top: 20px;

        ~ .bottomText {
            margin-top: 40px;
        }

        span {
            display: inline;
        }
    }

    @media (min-width: ${breakpoints.tabletMin}) and (max-width: 720px) {
        font-size: 12px;
        line-height: 16px;

        .processShort {
            margin-left: 0;
            margin-right: 0;
            width: 88%;
        }
        ul {
            width: 100%;
        }

        li {
            width: 25%;
        }
        ~ .bottomText {
            img {
                height: 55px;
            }
        }
    }
`;

export default VertragsserviceProcessExplanationShort;
