import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import styled from "styled-components";
import { Button, ContentWrapper, H1Css } from "../assets/styles/base";
import StarsBig from "../assets/svg/starsWithLine.svg";
import { breakpoints } from "../constants/breakpoints";
import { colors } from "../constants/colors";
import { urls } from "../constants/urls";

const VertragsserviceHeader = ({ image }) => {
    const { t } = useTranslation();
    const homeHeader = t("homeHeader", { returnObjects: true });

    return (
        <Wrapper>
            <LeftCol>
                <UpperContentWrapper>
                    <Title>
                        <Trans>homeHeader.title</Trans>
                    </Title>
                </UpperContentWrapper>
                <StarsBigBg />
                <BottomContentWrapper>
                    <Box>
                        <BulletsWrapper>
                            <h3>
                                <Trans>homeHeader.bulletsHeader</Trans>
                            </h3>

                            <ul>
                                {homeHeader.bullets.map((item, i) => (
                                    <li key={`bullet-${i}`}>{item}</li>
                                ))}
                            </ul>
                        </BulletsWrapper>
                        <a href={`${urls.survey}?vertragsservice`}>
                            <Button>
                                <Trans>homeHeader.getStartedNow</Trans>
                            </Button>
                        </a>
                    </Box>
                </BottomContentWrapper>
            </LeftCol>
            <RightCol hasLogo={!!image} img={image} />
        </Wrapper>
    );
};

const smallVersion = "919px";
const bigVersion = "920px";

const Wrapper = styled.div`
    position: relative;
    background-color: #f6f8f7;
    display: flex;

    @media (max-width: ${smallVersion}) {
        flex-direction: column-reverse;
    }
`;

const LeftCol = styled.div`
    box-sizing: border-box;
    width: 45%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 50px 0;

    @media (max-width: ${smallVersion}) {
        max-width: 460px;
        width: 100%;
        padding: 10px 0;
        padding: 0 30px;
    }
`;
const RightCol = styled.div`
    box-sizing: border-box;
    width: 60%;

    background-image: ${({ img }) => `url(${img});`};

    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center center;

    @media (max-width: ${smallVersion}) {
        background-image: ${({ img }) => `url(${img});`};

        background-position: center center;
        background-size: 920px 525px;
        height: 320px;
        margin: 0;
        width: 100%;
    }

    @media (max-width: 420px) {
        background-image: ${({ img }) => `url(${img});`};

        background-position: center center;
        background-size: 647px 553px;
        height: 350px;
        margin: 0;
        width: 100%;
    }
`;

const UpperContentWrapper = styled.div`
    box-sizing: border-box;
    margin: 0 0 18px auto;
    max-width: 680px;
    width: 100%;
    z-index: 1;

    @media (min-width: ${breakpoints.tabletBigMin}) {
        padding-left: 60px;
    }

    @media (max-width: ${smallVersion}) {
        padding-left: 0;
        padding-top: 25px;
    }

    @media (min-width: ${bigVersion}) {
        margin-bottom: 40px;
    }
`;
const StarsBigBg = styled.div`
    @media (min-width: ${bigVersion}) {
        position: relative;
        background-image: url(${StarsBig});
        background-position: bottom right;
        background-repeat: no-repeat;
        background-size: auto 100%;
        width: 140%;
        height: 140px;
        margin: -40px -25% -40px 0;
    }
    @media (min-width: 1260px) {
        width: 100%;
        margin: -40px 0 -40px -40px;
    }
    @media (max-width: ${smallVersion}) {
        margin-left: -110px;
        background-image: url(${StarsBig});
        background-position: bottom right;
        background-repeat: no-repeat;
        background-size: auto 100%;
        width: 150%;
        height: 60px;
    }
`;

const BottomContentWrapper = styled(ContentWrapper)`
    box-sizing: border-box;
    margin: 0 0 40px auto;
    max-width: 620px;
    padding: 0 0 0 30px;
    width: 100%;

    @media (max-width: ${smallVersion}) {
        padding-left: 0;
    }
    @media (min-width: ${bigVersion}) {
        padding-left: 60px;
    }
`;

const Title = styled.h1`
    ${H1Css};
    margin: 0;
`;

const BulletsWrapper = styled.div`
    font-size: 12px;
    text-align: left;

    h3 {
        font-size: 14px;
        font-weight: bold;
        margin: 0 0 10px;
    }
    ul {
        list-style: none;
        margin: 14px 0px 24px;
        padding: 0;
    }
    li {
        margin: 0 0 8px;
        padding-left: 15px;
        position: relative;

        :before {
            background: ${colors.primary};
            border-radius: 50%;
            box-sizing: border-box;
            color: #fff;
            content: "✓";
            font-size: 9px;
            height: 11px;
            left: -1px;
            line-height: 1.4;
            position: absolute;
            text-align: center;
            top: 2px;
            width: 11px;
        }
    }

    @media (min-width: ${bigVersion}) {
        font-size: 15px;

        h3 {
            font-size: 18px;
        }
        li {
            padding-left: 20px;

            :before {
                font-size: 12px;
                height: 15px;
                padding-left: 1px;
                top: 3px;
                width: 15px;
            }
        }
    }
`;

const Box = styled.div`
    position: relative;
    text-align: center;
    background: #fff;
    box-shadow: 0px 0px 30px #0000003f;
    border-radius: 30px;
    padding: 20px;

    @media (min-width: ${bigVersion}) {
        padding: 24px 25px;
        text-align: left;
        width: 330px;
    }
`;
export default VertragsserviceHeader;
